<template>
    <TsPanelCustomScroll :loading="loading">
        <ts-page-title
            :title="$t('driverProfile.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('driverProfile.pageTitle'),
                    href: '/admin/driver/driver-profiles'
                },
                {
                    text: $t('create'),
                    active: true
                }
            ]"
        />
        <div class="row">
            <div class="col-md-12 tw-space-y-5">
                <ts-panel>
                    <ts-panel-wrapper class="tw-space-y-2">
                        <div class="row">
                            <div class="col-md-6 tw-space-y-2">
                                <!-- personal-info -->
                                <PersonalInfo
                                    v-model="model"
                                    :validate="errors"
                                    ref="personalinfo"
                                />

                                <!-- id or passport -->
                                <IdOrPassport
                                    v-model="model"
                                    :validate="errors"
                                />
                            </div>
                            <div class="col-md-6 tw-space-y-2">
                                <!-- vehicle license -->
                                <VehicleLicense
                                    v-model="model"
                                    :validate="errors"
                                />

                                <!-- bank profiles -->
                                <BankAccount
                                    v-model="model"
                                    :validate="errors"
                                />
                            </div>
                        </div>
                    </ts-panel-wrapper>
                </ts-panel>
                <div class="row tw-space-y-4 sm:tw-space-y-0 lg:tw-space-y-0">
                    <div class="col-md-6">
                        <!-- place of birth -->
                        <PlaceOfBirth
                            v-model="model"
                            :validate="errors"
                            @loading="v => (loading = v)"
                        />
                    </div>
                    <div class="col-md-6">
                        <!-- current address -->
                        <CurrentAddress
                            v-model="model"
                            :validate="errors"
                            @loading="v => (loading = v)"
                        />
                    </div>
                </div>
                <div class="row tw-space-y-4 sm:tw-space-y-0 lg:tw-space-y-0">
                    <div class="col-md-12">
                        <FeeSetup
                            v-model="model"
                            :validate="errors"
                            v-if="model"
                        />
                    </div>
                </div>
                <div class="row tw-space-y-4 sm:tw-space-y-0 lg:tw-space-y-0">
                    <div class="col-md-12">
                        <Schedule v-model="model" :validate="errors" />
                    </div>
                </div>
            </div>
        </div>
        <br />
        <template v-slot:footer>
            <ts-button
                @click.prevent="$router.push({ name: 'driver-profile' })"
                :disabled="loading"
                >{{ $t('cancel') }}</ts-button
            >
            <ts-button
                color="primary"
                @click.prevent="onUpdate"
                :disabled="loading"
                >{{ $t('update') }}</ts-button
            >
        </template>
    </TsPanelCustomScroll>
</template>

<script>
import { Errors } from 'form-backend-validation'
import PersonalInfo from './components/personal-info.vue'
import IdOrPassport from './components/id-or-passport.vue'
import VehicleLicense from './components/vehicle-license.vue'
import BankAccount from './components/bank-account.vue'
import PlaceOfBirth from './components/place-of-birth.vue'
import CurrentAddress from './components/current-address.vue'
import FeeSetup from './components/fee.vue'
import Schedule from './components/schedule.vue'

import { mapActions, mapState } from 'vuex'

export default {
    name: 'driverProfileEdit',
    components: {
        PersonalInfo,
        IdOrPassport,
        VehicleLicense,
        BankAccount,
        PlaceOfBirth,
        CurrentAddress,
        FeeSetup,
        Schedule
    },
    data() {
        return {
            errors: new Errors(),
            loading: false,
            model: {
                driver_id: '',
                driver_code: '',
                driver_name: '',
                photo: '',
                sex: '',
                dob: undefined,
                phone1: '',
                phone2: '',
                phone3: '',
                id_passport_no: '',
                id_passport_issue_date: undefined,
                id_passport_expiry_date: undefined,
                emergency_contact: '',
                emergency_relationship: '',
                contract_start_date: undefined,
                contract_end_date: undefined,
                is_internal_driver: false,
                is_active: true,
                province_code: undefined,
                district_code: undefined,
                commune_code: undefined,
                village_code: undefined,
                pob_province_code: undefined,
                pob_district_code: undefined,
                pob_commune_code: undefined,
                pob_village_code: undefined,
                plat_number: '',
                vehicle_type_id: undefined,
                driver_license_no: '',
                driver_license_issue_date: '',
                driver_license_expiry_date: '',
                guarantor1: '',
                guarantor1_phone: '',
                guarantor2: '',
                guarantor2_phone: '',
                bank_id: undefined,
                bank_acc_number: '',
                bank_acc_name: '',
                user_name: '',
                password: null,
                password_confirmation: null,
                pickup_fee: 0,
                express_vip_delivery_fee: null,
                express_vip_delivery_fee_type: '$',
                express_normal_delivery_fee: null,
                express_normal_delivery_fee_type: '$',
                standard_vip_delivery_fee: null,
                standard_vip_delivery_fee_type: '$',
                standard_normal_delivery_fee: null,
                standard_normal_delivery_fee_type: '$',
                sch_monday: true,
                sch_tuesday: true,
                sch_wednesday: true,
                sch_thursday: true,
                sch_friday: true,
                sch_saturday: true,
                sch_sunday: true,
                sch_start_time: undefined,
                sch_stop_time: undefined,
                vehicle_color: undefined,

                is_global_fee: true,
                driver_fees: []
            }
        }
    },
    computed: {
        ...mapState('driver/driverProfile', ['edit_data', 'formModels'])
    },
    methods: {
        ...mapActions('driver/driverProfile', ['getFormViewData', 'find']),
        fetchFormView({ params }) {
            this.loading = true
            this.getFormViewData({ params: params })
                .catch(err => {
                    this.$notify({ type: 'error', text: err.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onUpdate() {
            this.errors = new Errors()
            this.loading = true
            this.$store
                .dispatch('driver/driverProfile/update', {
                    id: this.model.driver_id,
                    data: this.model
                })
                .then(async response => {
                    await this.uploadLogo(this.model.driver_id)
                    this.$notify({ type: 'success', text: response.message })
                    this.$router.push({ name: 'driver-profile' })
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.$notify({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.loading = false
                })
        },
        async uploadLogo(driverId) {
            if (this.model.photo instanceof File) {
                let formData = new FormData()
                formData.append('file', this.model.photo)
                formData.append('driver_id', driverId)
                let response = await this.$store.dispatch(
                    'driver/driverProfile/upload',
                    formData
                )
                return response
            }
            return ''
        },
        async setEdit() {
            await this.find(this.$route.params.id)
            await this.fetchFormView({
                params: {
                    provinceCode: this.edit_data.province_code,
                    districtCode: this.edit_data.district_code,
                    communeCode: this.edit_data.commune_code,
                    pprovinceCode: this.edit_data.pob_province_code,
                    pdistrictCode: this.edit_data.pob_district_code,
                    pcommuneCode: this.edit_data.pob_commune_code
                }
            })

            this.model.driver_id = this.edit_data.driver_id
            this.model.driver_code = this.edit_data.driver_code
            this.model.driver_name = this.edit_data.driver_name
            this.model.photo = this.edit_data.photo
            this.model.sex = this.edit_data.sex
            this.model.dob = this.edit_data.dob
            this.model.phone1 = this.edit_data.phone1
            this.model.phone2 = this.edit_data.phone2
            this.model.phone3 = this.edit_data.phone3
            this.model.id_passport_no = this.edit_data.id_passport_no
            this.model.id_passport_issue_date =
                this.edit_data.id_passport_issue_date
            this.model.id_passport_expiry_date =
                this.edit_data.id_passport_expiry_date
            this.model.emergency_contact = this.edit_data.emergency_contact
            this.model.emergency_relationship =
                this.edit_data.emergency_relationship
            this.model.contract_start_date = this.edit_data.contract_start_date
            this.model.contract_end_date = this.edit_data.contract_end_date
            this.model.is_internal_driver = this.edit_data.is_internal_driver
            this.model.is_active = this.edit_data.is_active
            this.model.province_code = this.edit_data.province_code
            this.model.district_code = this.edit_data.district_code
            this.model.commune_code = this.edit_data.commune_code
            this.model.village_code = this.edit_data.village_code
            this.model.pob_province_code = this.edit_data.pob_province_code
            this.model.pob_district_code = this.edit_data.pob_district_code
            this.model.pob_commune_code = this.edit_data.pob_commune_code
            this.model.pob_village_code = this.edit_data.pob_village_code
            this.model.plat_number = this.edit_data.plat_number
            this.model.vehicle_type_id = this.edit_data.vehicle_type_id
            this.model.driver_license_no = this.edit_data.driver_license_no
            this.model.driver_license_issue_date =
                this.edit_data.driver_license_issue_date
            this.model.driver_license_expiry_date =
                this.edit_data.driver_license_expiry_date
            this.model.guarantor1 = this.edit_data.guarantor1
            this.model.guarantor1_phone = this.edit_data.guarantor1_phone
            this.model.guarantor2 = this.edit_data.guarantor2
            this.model.guarantor2_phone = this.edit_data.guarantor2_phone
            this.model.bank_id = this.edit_data.bank_id
            this.model.bank_acc_number = this.edit_data.bank_acc_number
            this.model.bank_acc_name = this.edit_data.bank_acc_name
            this.model.pickup_fee = this.edit_data.pickup_fee
            this.model.express_vip_delivery_fee =
                this.edit_data.express_vip_delivery_fee
            this.model.express_vip_delivery_fee_type =
                this.edit_data.express_vip_delivery_fee_type
            this.model.express_normal_delivery_fee =
                this.edit_data.express_normal_delivery_fee
            this.model.express_normal_delivery_fee_type =
                this.edit_data.express_normal_delivery_fee_type
            this.model.standard_vip_delivery_fee =
                this.edit_data.standard_vip_delivery_fee
            this.model.standard_vip_delivery_fee_type =
                this.edit_data.standard_vip_delivery_fee_type
            this.model.standard_normal_delivery_fee =
                this.edit_data.standard_normal_delivery_fee
            this.model.standard_normal_delivery_fee_type =
                this.edit_data.standard_normal_delivery_fee_type
            ;(this.model.sch_start_time = this.edit_data.sch_start_time
                ? this.edit_data.sch_start_time
                : undefined),
                (this.model.sch_stop_time = this.edit_data.sch_stop_time
                    ? this.edit_data.sch_stop_time
                    : undefined),
                (this.model.sch_monday = this.edit_data.sch_monday)
            this.model.sch_tuesday = this.edit_data.sch_tuesday
            this.model.sch_wednesday = this.edit_data.sch_wednesday
            this.model.sch_thursday = this.edit_data.sch_thursday
            this.model.sch_friday = this.edit_data.sch_friday
            this.model.sch_saturday = this.edit_data.sch_saturday
            this.model.sch_sunday = this.edit_data.sch_sunday
            this.model.vehicle_color = this.edit_data.vehicle_color
            this.model.is_global_fee = this.edit_data.is_global_fee
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.setEdit()
        })
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit('driver/driverProfile/SET_EDIT_DATA', [])
        this.$store.commit('driver/driverProfile/RESET_STATE')
        next()
    }
}
</script>
