var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TsPanelCustomScroll',{attrs:{"loading":_vm.loading},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('ts-button',{attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.$router.push({ name: 'driver-profile' })}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('ts-button',{attrs:{"color":"primary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.onUpdate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('update')))])]},proxy:true}])},[_c('ts-page-title',{attrs:{"title":_vm.$t('driverProfile.pageTitle'),"breadcrumb":[
            { text: _vm.$t('home'), href: '/' },
            {
                text: _vm.$t('driverProfile.pageTitle'),
                href: '/admin/driver/driver-profiles'
            },
            {
                text: _vm.$t('create'),
                active: true
            }
        ]}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 tw-space-y-5"},[_c('ts-panel',[_c('ts-panel-wrapper',{staticClass:"tw-space-y-2"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 tw-space-y-2"},[_c('PersonalInfo',{ref:"personalinfo",attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('IdOrPassport',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('div',{staticClass:"col-md-6 tw-space-y-2"},[_c('VehicleLicense',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}),_c('BankAccount',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)])])],1),_c('div',{staticClass:"row tw-space-y-4 sm:tw-space-y-0 lg:tw-space-y-0"},[_c('div',{staticClass:"col-md-6"},[_c('PlaceOfBirth',{attrs:{"validate":_vm.errors},on:{"loading":function (v) { return (_vm.loading = v); }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('div',{staticClass:"col-md-6"},[_c('CurrentAddress',{attrs:{"validate":_vm.errors},on:{"loading":function (v) { return (_vm.loading = v); }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)]),_c('div',{staticClass:"row tw-space-y-4 sm:tw-space-y-0 lg:tw-space-y-0"},[_c('div',{staticClass:"col-md-12"},[(_vm.model)?_c('FeeSetup',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_vm._e()],1)]),_c('div',{staticClass:"row tw-space-y-4 sm:tw-space-y-0 lg:tw-space-y-0"},[_c('div',{staticClass:"col-md-12"},[_c('Schedule',{attrs:{"validate":_vm.errors},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)])],1)]),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }